<script setup lang="ts">
import { useElementBounding } from '@vueuse/core'
import { addDays, isSameDay } from 'date-fns'
import { Ref } from 'nuxt/dist/app/compat/capi'
import { TranslationKey } from '~/i18n/TranslationKeys'
import { EmitsEnum } from '~~/src/constants/emits'
import { TextSizes } from '~~/src/constants/textSizes'
import { utilArray } from '~~/src/utils/utilArray'
import { utilDate } from '~~/src/utils/utilDate'

const props = defineProps({
	currentDate: { type: Date, default: new Date() },
	displayedElements: { type: Number, default: 5 },
})
const { currentDate, displayedElements } = toRefs(props)
const emit = defineEmits([EmitsEnum.Change])

const target = ref()
const scrollWrap = ref()
const preventInteraction = ref(false)
const animationClasses = ['transition-all', 'duration-500', 'ease-in-out']
const locale = useLocale().currentLocale

const { width } = useElementBounding(target)
const elementWidth = computed(() => Math.floor(width.value / displayedElements.value))
// TODO: replace
const wrapperPadding = computed(() => Math.max((width.value % displayedElements.value) / 2).toFixed(2))

const applyDates = (next: Date, prev?: Date) => {
	if (prev == undefined || isSameDay(next, prev)) {
		dates.value = []
		for (let i = -2; i < 3; i++) {
			dates.value.push(addDays(next, i))
		}

		return
	}

	preventInteraction.value = true
	const daysDifference = utilDate.daysDifference({ from: prev, to: next })
	const translateAmount = -daysDifference * elementWidth.value + 'px'
	const functionToUse = daysDifference > 0 ? animateForward : animateBackward

	functionToUse(daysDifference, translateAmount).then(() => (preventInteraction.value = false))
}

const animateForward = async (daysDifference: number, translateAmount: string) => {
	animationClasses.forEach((el) => scrollWrap?.value?.classList?.add(el))
	const originalLength = dates.value.length
	const lastDay = dates.value[originalLength - 1]

	for (let i = 1; i <= daysDifference; i++) {
		dates.value.push(addDays(lastDay, i))
	}

	scrollWrap!.value!.style!.transform! = `translateX(${translateAmount})`

	await setTimeout(() => {
		animationClasses.forEach((el) => scrollWrap?.value?.classList?.remove(el))
		scrollWrap!.value!.style!.transform! = ``

		dates.value = utilArray.startingFrom(dates.value, daysDifference)
	}, 500)
}
const animateBackward = async (daysDifference: number, translateAmount: string) => {
	scrollWrap!.value!.style!.transform! = `translateX(-${translateAmount})`
	const firstDay = dates.value[0]

	for (let i = -1; i >= daysDifference; i--) {
		dates.value.unshift(addDays(firstDay, i))
	}

	await setTimeout(async () => {
		animationClasses.forEach((el) => scrollWrap?.value?.classList?.add(el))
		scrollWrap!.value!.style!.transform! = ``

		await setTimeout(() => {
			animationClasses.forEach((el) => scrollWrap?.value?.classList?.remove(el))
			dates.value = utilArray.stopAt(dates.value, 5)
		}, 500)
	}, 1)
}

const dates: Ref<Date[]> = ref([])
applyDates(currentDate.value)
watch(currentDate, applyDates)

const onDateClick = (date: Date) => {
	if (!preventInteraction.value) {
		emit(EmitsEnum.Change, date)
	}
}
</script>

<template>
	<div class="w-full">
		<div class="w-full overflow-hidden" ref="target">
			<div ref="scrollWrap" :style="`display: -webkit-box; padding: 0 ${wrapperPadding}px`">
				<div
					v-for="date in dates"
					class="flex h-14 grow flex-col items-center justify-center"
					:class="
						isSameDay(date, currentDate)
							? 'border-b-2 border-b-sky-600'
							: 'border-b border-b-dark-blue-200 text-dark-blue-400'
					"
					:style="`width: ${elementWidth}px`"
					@click="onDateClick(date)"
				>
					<CommonText
						:text="utilDate.getCapitalDayOfWeek(date, locale) as TranslationKey"
						:text-size="isSameDay(date, currentDate) ? TextSizes.HEADING_MD : TextSizes.BODY_REGULAR"
					/>
					<CommonText :text="date.getDate().toString() as TranslationKey" :text-size="TextSizes.BODY_REGULAR" />
				</div>
			</div>
		</div>
	</div>
</template>
