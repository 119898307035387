<script setup lang="ts">
import { useElementBounding, useWindowSize } from '@vueuse/core'
import { PropType } from 'nuxt/dist/app/compat/capi'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { ButtonSizes } from '~~/src/constants/buttonSizes'
import { ButtonTypes } from '~~/src/constants/buttonTypes'
import { TextSizes } from '~~/src/constants/textSizes'
import { useMobileSheetsStore } from '~~/src/store/mobileSheets'
import { GenericMobileSheetConfiguration } from '~~/src/types/GenericMobileSheetConfiguration'
import { Localizable } from '~~/src/types/Localizable'
import { mobileSheetsConfig } from '~/config/mobileSheets'
import { ComponentWithProps } from '~~/src/types/ComponentWithProp'
import { TranslationKeys } from '~/i18n/TranslationKeys'

const props = defineProps({
	config: { type: Object as PropType<GenericMobileSheetConfiguration>, required: true },
})
const { config } = toRefs(props)

const mobileSheetsStore = useMobileSheetsStore()

const footerRef = ref()
const headerRef = ref()
const { height: footerHeight } = useElementBounding(footerRef)
const { height: headerHeight } = useElementBounding(headerRef)
const { height } = useWindowSize()
const { paddingFromTopInPx } = mobileSheetsConfig

const spacerStyle = computed(
	() => 'height: ' + Math.max(footerHeight.value, mobileSheetsConfig.contentPaddingInPx) + 'px;'
)

// @ts-ignore
const isLocaTitle = computed(() => Object.values(TranslationKeys).includes(config.value.title?.key))

const onClose = () => useMobileSheetsStore().closeMobileSheet()
onBeforeUnmount(() => {
	if (config.value.onClose != undefined) {
		config.value.onClose()
	}
})

const slots = useSlots()
const hasHeader = computed(() => slots.header != undefined)

const containerHeight = computed(() => {
	return `height: calc(100% - ${headerHeight.value}px  )`
})
const maxFooterHeight = computed(() => height.value - (paddingFromTopInPx + headerHeight.value))
watch(maxFooterHeight, () => (mobileSheetsStore.footerHeight = maxFooterHeight.value))
</script>

<template>
	<div class="h-full" style="grid-template-rows: auto 1fr">
		<div class="flex flex-col gap-5 pt-5" ref="headerRef">
			<div>
				<div
					class="flex shrink-0 items-center justify-between px-4"
					:style="`padding-bottom: ${mobileSheetsConfig.contentPaddingInPx}px;`"
				>
					<div class="flex w-11/12 shrink flex-col gap-2">
						<CommonText v-if="config.label != undefined" :text="config.label" :text-size="TextSizes.PRODUCTIVE" />
						<CommonText
							v-if="isLocaTitle"
							:text="config.title as Localizable"
							:text-size="TextSizes.HEADING_MD"
							class="text-sky-600"
						/>
						<component
							v-else
							:is="(config.title as ComponentWithProps).component"
							v-bind="(config.title as ComponentWithProps).props"
						/>
					</div>
					<CommonIconButton
						v-if="!config.preventClose"
						:icon="SpSvg.BasicTimes"
						:type="ButtonTypes.GHOST"
						:tooltip="TranslationKeys.CLOSE"
						:size="ButtonSizes.ICON_M"
						@click="onClose"
						:class="'shrink-0 self-start'"
					/>
				</div>

				<div v-if="hasHeader" class="w-full">
					<slot name="header" />
				</div>
			</div>
		</div>

		<div class="overflow-y-auto px-4 pt-7" :style="containerHeight">
			<slot name="default" />
			<div class="shrink-0" :style="spacerStyle" />
		</div>

		<div
			class="fixed bottom-0 left-0 z-10 w-full overflow-y-auto bg-white"
			ref="footerRef"
			:style="`max-height: ${maxFooterHeight}px;`"
		>
			<slot name="footer" />
		</div>
	</div>
</template>
