<script setup lang="ts">
import { SpNavigationMobile } from '@dev.smartpricing/sp-vue-components'
import { FunctionalIds } from '../constants/funcitonalIds'
import { getMainNavigationItems, getBottomNavigationItems } from '@/config/navigationItems'
import { useMobileActionsStore } from '~/store/mobileActions'
import { ButtonSizes } from '~/constants/buttonSizes'
import { ButtonTypes } from '~/constants/buttonTypes'
import { SpSvg } from '~/autogen/SpSvg'
import { IconSizes } from '~/constants/iconSizes'

const bottomNavigationItems = computed(() => [...getBottomNavigationItems()])

const backgroundColor = computed(() => {
	const routeName = useRoute().name

	switch (routeName) {
		case 'calendar':
			return 'bg-white'
		case 'settings-accommodation':
		case 'settings-language':
		case 'settings-notifications':
		case 'settings-billing':
		case 'updates':
			return 'bg-warm-grey-100'
		default:
			return 'bg-sky-50'
	}
})
const mobileActionsStore = useMobileActionsStore()
const { toggleValue: toggleMobileActionsValue, toggle: toggleMobileActions } = useToggle(false)
const { toggleValue: toggleMenuValue, toggle: toggleMenu } = useToggle(false)

const toggleMobileActionsWrapper = () => {
	window.dispatchEvent(new CustomEvent('toggledMobileActions'))

	toggleMobileActions()
}

const mobileMenuWrapperRef = ref()
const isBodyLocked = useScrollLock(document.body)
const onMenuOpen = (isOpen: boolean) => {
	if (isOpen) {
		isBodyLocked.value = true
	} else {
		isBodyLocked.value = false
	}
}
</script>

<template>
	<div class="relative h-dynamic overflow-hidden" :class="backgroundColor">
		<div class="fixed left-0 right-0 top-0 z-[9999] h-0 lg:left-[60px] lg:z-[999]" id="dialogs-container"></div>
		<div class="fixed z-[999999] h-0 w-0" id="dropdowns-container"></div>
		<div class="top-0 z-20 mt-[60px] shadow">
			<SpNavigationMobile
				:main-navigation-items="getMainNavigationItems()"
				:bottom-navigation-items="bottomNavigationItems"
				class="mt-[-60px] block lg:hidden"
				ref="mobileMenuWrapperRef"
				@open="onMenuOpen"
			>
				<template #header-action>
					<!-- additional actions (on the right) + badge (on the left) -->
					<Transition name="mobile-options-animation">
						<div
							v-if="!toggleMenuValue && useRoute().path.endsWith('calendar')"
							class="flex w-full items-center justify-end"
						>
							<CommonButton
								:size="ButtonSizes.TEXT_M"
								@click="toggleMobileActionsWrapper"
								:type="ButtonTypes.GHOST"
								class="self-end !border-white !px-2"
							>
								<CommonIcon :icon-name="SpSvg.BasicDots" :icon-size="IconSizes.M" :fill="'fill-white'" />
							</CommonButton>
						</div>
						<div v-else-if="useRoute().path.endsWith('strategies')">
							<StrategiesControlsMobile />
						</div>

						<div v-else-if="useRoute().path.endsWith('competitors')">
							<CompetitorsControlsMobile />
						</div>
					</Transition>
				</template>
			</SpNavigationMobile>

			<MakeWishManager />

			<AnimationCollapse v-if="mobileActionsStore.hasActionHandler">
				<div v-if="toggleMobileActionsValue" class="border-b border-sky-100 bg-sky-50 px-4 py-2">
					<component :is="mobileActionsStore.actionHandler" />
				</div>
			</AnimationCollapse>

			<CommonBannerManager class="sticky z-30 pb-3" />
			<div :id="FunctionalIds.ControlBarContainer"></div>
		</div>

		<slot />
		<div :id="FunctionalIds.MobileMenuContainer"></div>
		<CommonBottomControlBarContainer />
		<DevToolsSalesModesBadge class="z-50" />

		<MobileSheetsManager class="absolute left-0 top-0 z-20" />
		<ModalManager class="absolute left-0 top-0 z-20" />

		<div :id="FunctionalIds.TooltipContainer" class="absolute left-0 top-0" style="z-index: 99" />
		<CommonNotificationsManager />
		<PopupManager class="z-50" />
	</div>
</template>
