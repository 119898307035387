<script lang="ts" setup>
import { PropType } from 'nuxt/dist/app/compat/capi'
import { TranslationKey, TranslationKeys } from '~/i18n/TranslationKeys'
import { SpSvg } from '~~/src/autogen/SpSvg'
import { EmitsEnum } from '~~/src/constants/emits'
import { IconSizes } from '~~/src/constants/iconSizes'
import { TextSizes } from '~~/src/constants/textSizes'
import { UserflowId } from '~~/src/constants/UserflowId'
import { useToggle } from '~~/src/hooks/useToggle'
import { Currency } from '~~/src/submodules/sharedTypes/common/Currency'
import { utilNumber } from '~~/src/utils/UtilNumber'
import { TrackingMessages } from '../../../constants/trackingMessages'

const { t } = useI18n()

const props = defineProps({
	totalRooms: { type: Number, required: true },
	occupiedRooms: { type: Number, required: true },
	occupiedRoomsLastYear: { type: Number, required: true },
	currentAdr: { type: Number, required: true },
	lastYearAdr: { type: Number, required: true },
	currency: { type: Object as PropType<Currency>, required: true },
	expand: { type: Boolean, default: false },
	hideLastYearInfo: { type: Boolean, default: false },
})
const { totalRooms, occupiedRooms, occupiedRoomsLastYear, currency, expand, hideLastYearInfo } = toRefs(props)
const emit = defineEmits([EmitsEnum.Toggle])

const { toggle, toggleValue, setToggle } = useToggle(expand.value)
watch(expand, () => setToggle(expand.value))

const onToggle = () => {
	toggle()
	emit(EmitsEnum.Toggle, toggleValue.value)
}
const rotate = computed(() => (toggleValue.value ? '' : 'rotate-180'))

const howDoesItWorkDialogOpen = ref(false)
const openHowDoesItWorkDialog = () => {
	howDoesItWorkDialogOpen.value = true

	utilTracking.track(TrackingMessages.HOW_DOES_IT_WORK, {
		feature_name: 'Occupancy calculation',
	})
}
</script>

<template>
	<div>
		<CommonCollapsableCard
			second-card-color="bg-warm-grey-700"
			:data-id="UserflowId.OccupancyCard"
			:custom-y="toggleValue ? undefined : -52"
			:custom-y-action="toggleValue ? undefined : -62"
		>
			<template v-slot:header>
				<div
					class="grid h-full cursor-pointer gap-2 bg-warm-grey-200 px-4 py-3 hover:bg-warm-grey-300"
					style="grid-template-columns: 7fr auto 3fr"
					@click="onToggle"
				>
					<PriceOccupancyCompactHeader
						:occupied-rooms="occupiedRooms"
						:total-rooms="totalRooms"
						:occupied-rooms-last-year="occupiedRoomsLastYear"
						:show-comparison="toggleValue"
						:hide-badge="hideLastYearInfo"
					/>

					<div class="border-l border-l-warm-grey-500" />

					<PriceADRCompactHeader
						:currency="currency"
						:current-adr="currentAdr"
						:show-comparison="toggleValue"
						:last-year-adr="lastYearAdr"
						:hide-badge="hideLastYearInfo"
					/>
				</div>
			</template>
			<div class="flex cursor-pointer flex-col gap-2 px-4 py-3" @click="onToggle">
				<CommonText
					:text="TranslationKeys.PAST_DATA_NOT_AVAILABLE"
					:text-size="TextSizes.BODY_SEMIBOLD"
					class="mb-2 text-warm-grey-200"
					v-if="hideLastYearInfo"
				/>
				<div v-else class="grid h-full gap-2" style="grid-template-columns: 7fr auto 3fr">
					<PriceOccupancyBody
						:occupied-rooms-last-year="occupiedRoomsLastYear"
						:total-rooms="totalRooms"
						:hide-last-year-info="hideLastYearInfo"
					/>

					<div class="border-l border-l-white"></div>

					<div>
						<CommonText
							:text="utilNumber.toCurrency(lastYearAdr, currency) as TranslationKey"
							:text-size="TextSizes.HEADING_MD"
							class="text-white"
						/>
					</div>
				</div>

				<div class="flex gap-2">
					<CommonText :text="TranslationKeys.LAST_YEAR" :text-size="TextSizes.PRODUCTIVE" class="text-warm-grey-200" />
					<CommonIcon
						:class="` shrink-0 transition-all duration-300 ease-out ${rotate}`"
						:icon-name="SpSvg.BasicCarretUp"
						:fill="'fill-warm-grey-200'"
						:icon-size="IconSizes.XS"
					/>
				</div>
			</div>
			<template v-slot:action>
				<SpButton
					:type="SpButtonType.Interactive"
					:text="t(TranslationKeys.HOW_DOES_IT_WORK)"
					:data-id="UserflowId.HowDoesItWorkLauncherOccupancyCalc"
					custom-class="py-2 pt-4 w-full border-none"
					@click="openHowDoesItWorkDialog"
				>
					<template #prependIcon>
						<img src="~/assets/images/animated-inside-the-box.gif" class="h-5 w-5" />
					</template>
				</SpButton>
			</template>
		</CommonCollapsableCard>
		<ModalHowDoesOccupancyWorkModal v-model="howDoesItWorkDialogOpen" />
	</div>
</template>
