<script setup lang="ts">
import { SpButtonType, SpButtonSize, SpGeneratedIcon } from '@dev.smartpricing/sp-vue-components'

const marketIntelligenceStore = useMarketIntelligenceStore()

// Actions handling
const onAddCompetitor = () => {
	window.dispatchEvent(new Event('add-competitor'))
}
const onResetCompetitors = () => {
	window.dispatchEvent(new Event('reset-competitors'))
}

const onSave = () => {
	marketIntelligenceStore.updateMarketIntelligenceData()
}

onUnmounted(() => {
	mapBoxInstance.resetInstance()
})
</script>

<template>
	<div class="flex w-full items-center justify-end gap-2">
		<SpButton
			:size="SpButtonSize.DefaultIconOnly"
			:type="SpButtonType.Ghost"
			:iconName="SpGeneratedIcon.BasicUndo"
			custom-class="text-white transition-none border border-white fill-white"
			@click="onResetCompetitors"
		/>
		<SpButton
			:size="SpButtonSize.DefaultIconOnly"
			:type="SpButtonType.Ghost"
			:iconName="SpGeneratedIcon.BasicPlus"
			custom-class="text-white transition-none border border-white fill-white"
			@click="onAddCompetitor"
		/>
		<SpButton
			:size="SpButtonSize.DefaultIconOnly"
			:type="SpButtonType.Ghost"
			:iconName="SpGeneratedIcon.BasicFile"
			custom-class="text-white transition-none border border-white fill-white"
			:is-disabled="!marketIntelligenceStore.isCompsetChanged"
			@click="onSave"
		/>
	</div>
</template>
